// https://reactjs.org/docs/integrating-with-other-libraries.html
// https://itnext.io/advanced-react-redux-techniques-how-to-use-refs-on-connected-components-e27b55c06e34
import { Component } from 'react'
import { ElementObserver } from 'viewprt'

interface ViewportWrapperProps {
  onEnter?: (ref: HTMLDivElement) => void
  offset?: number
  key?: string | number
}

export class ViewportWrapper extends Component<ViewportWrapperProps> {
  el: HTMLDivElement | null = null

  componentDidMount() {
    const { onEnter, offset = 100 } = this.props
    if (this.el) {
      const el = this.el
      ElementObserver(el, {
        offset,
        once: true,
        onEnter: () => onEnter && onEnter(el),
      })
    }
  }

  render() {
    const { key, children } = this.props
    return (
      <div className="viewport-wrapper" ref={(el) => (this.el = el)} key={key}>
        {children}
      </div>
    )
  }
}
