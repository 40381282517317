import { CommunityTextPostClipFragment } from 'types/generated/CommunityTextPostClipFragment'
import { CommPostVotes } from 'components/CommPost/CommPostVotes'
import Link from 'components/Link'
import { ClipBody } from './ClipBody'
import { ClipHeader } from './ClipHeader'
import { ClipFooter } from './ClipFooter'
import style from './index.module.css'
import { useCurrentUser } from 'hooks/useCurrentUser'

export function CommPostClip({
  post,
  votingDisabled,
}: {
  post: CommunityTextPostClipFragment
  votingDisabled?: boolean
}) {
  const { user } = useCurrentUser()
  const {
    title,
    prosedoc,
    path,
    createdAt,
    status,
    author: { username: authorUsername },
  } = post
  const userViewingOwnClip = user?.username === authorUsername

  const splitPath = path.split('/')

  const topic = splitPath[1]

  const clipToDisplay = (
    <div className={style.wrapper}>
      {!votingDisabled && (
        <div className={style.fullWidthVoteContainer}>
          <CommPostVotes post={post} />
        </div>
      )}

      <Link rel="ugc" className={style.commPostLink} href={`${path}`}>
        <ClipHeader createdAt={createdAt} title={title} topic={topic} />
        <ClipBody path={path} prosedoc={prosedoc} />
        <ClipFooter post={post} />
      </Link>
    </div>
  )

  if (status === 'hidden' && userViewingOwnClip) {
    return clipToDisplay
  }

  if (status !== 'published') {
    return null
  }

  return clipToDisplay
}
