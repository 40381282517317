import React from 'react'
import { ClipAuthor } from './ClipAuthor'
import { CommPostVotes } from 'components/CommPost/CommPostVotes'
import styles from './index.module.css'
import { CommunityTextPostClipFragment } from 'types/generated/CommunityTextPostClipFragment'
import CommentIcon from 'components/IconSvgs/Comment'
import { joinPaths } from 'lib/urls'
import Link from 'components/Link'

interface Props {
  post: CommunityTextPostClipFragment
  votingDisabled?: boolean
}

export const ClipFooter = ({ post, votingDisabled }: Props) => {
  const { commentCount } = post
  const createCommentPath = joinPaths(post.path, '/comments/new')
  return (
    // must be wrapped in an empty div — otherwise css rules will not apply

    <div>
      <div className={styles.footerWrapper}>
        <ClipAuthor post={post} />
        <div className={styles.footerRight}>
          <Link
            rel="nofollow"
            href={createCommentPath}
            className={styles.commentCountLink}
          >
            <div className={styles.commentCountContainer}>
              <CommentIcon />
              {commentCount > 0 && commentCount}
            </div>
          </Link>

          <div className={styles.mobileVoteContainer}>
            {!votingDisabled && <CommPostVotes post={post} />}
          </div>
        </div>
      </div>
    </div>
  )
}
