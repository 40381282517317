import React, { useEffect, useState } from 'react'
import * as gtag from 'lib/gtag'
import { DismissibleModal } from 'components/DismissibleModal'
import { OAuthSignInButton } from 'components/OAuthSignInButton'
import Link from 'components/Link'
import { useCommPostPermissions } from 'components/CommPost/useCommPostPermissions'
import { Upvote } from 'components/IconSvgs/Upvote'
import { Downvote } from 'components/IconSvgs/Downvote'
import { useVoteOnCommunityPost } from '../useVoteOnCommunityPost'
import { createRedirectPath, createDippUrl } from 'lib/urls'
import style from './index.module.css'
import { CommunityTextPostClipFragment } from 'types/generated/CommunityTextPostClipFragment'
import { CommunityPostByPublicId_commPost } from 'types/generated/CommunityPostByPublicId'

const LIKE = 1
const NEUTRAL = 0
const DISLIKE = -1

type VoteType = typeof LIKE | typeof NEUTRAL | typeof DISLIKE

interface CommunityPostVoteState {
  usersVote: VoteType
  upvoteCount: number
  downvoteCount: number
}

export const CommPostVotes = ({
  post,
}: {
  post: CommunityPostByPublicId_commPost | CommunityTextPostClipFragment
}) => {
  const [showModal, setShowModal] = useState(false)
  const [voteState, setVoteState] = useState<{
    current: CommunityPostVoteState
    previous: CommunityPostVoteState
  }>({
    current: {
      usersVote: post.usersVote as VoteType,
      upvoteCount: post.upvoteCount,
      downvoteCount: post.downvoteCount,
    },
    previous: {
      usersVote: post.usersVote as VoteType,
      upvoteCount: post.upvoteCount,
      downvoteCount: post.downvoteCount,
    },
  })

  const { canVote } = useCommPostPermissions()
  const { upvote, unvote, downvote, error } = useVoteOnCommunityPost(
    post as any
  )

  useEffect(() => {
    if (error && voteState.current !== voteState.previous) {
      setVoteState({ ...voteState, current: voteState.previous })
    }
  }, [error, voteState])

  const voteClickHandler = (newVote: VoteType, e: any) => {
    e.preventDefault()
    const {
      usersVote: currentVote,
      upvoteCount: currentUpvoteCount,
      downvoteCount: currentDownvoteCount,
    } = voteState.current

    if (newVote === LIKE && currentVote !== LIKE) {
      setVoteState({
        current: {
          usersVote: newVote,
          upvoteCount: currentUpvoteCount + 1,
          downvoteCount:
            currentVote === DISLIKE
              ? currentDownvoteCount - 1
              : currentDownvoteCount,
        },
        previous: voteState.current,
      })
      gtag.commentUpvoteLink()
      canVote ? upvote() : setShowModal(true)
    } else if (newVote === DISLIKE && currentVote !== DISLIKE) {
      setVoteState({
        current: {
          usersVote: newVote,
          upvoteCount:
            currentVote === LIKE ? currentUpvoteCount - 1 : currentUpvoteCount,
          downvoteCount: currentDownvoteCount + 1,
        },
        previous: voteState.current,
      })
      gtag.commentDownvoteLink()
      canVote ? downvote() : setShowModal(true)
    } else {
      setVoteState({
        current: {
          usersVote: newVote,
          upvoteCount:
            currentVote === LIKE ? currentUpvoteCount - 1 : currentUpvoteCount,
          downvoteCount:
            currentVote === DISLIKE
              ? currentDownvoteCount - 1
              : currentDownvoteCount,
        },
        previous: voteState.current,
      })
      canVote ? unvote() : setShowModal(true)
    }
  }

  const { usersVote, upvoteCount, downvoteCount } = voteState.current

  return (
    <>
      {showModal ? (
        <DismissibleModal
          onDismiss={() => {
            setVoteState({ ...voteState, current: voteState.previous })
            setShowModal(false)
          }}
        >
          <div style={{ height: '100%' }}>
            <div>
              <h3 className={style.modalHeader}>
                {usersVote === LIKE && 'Liked this post?'}
                {usersVote === DISLIKE && 'Disliked this post?'}
                <br />
                <span className={style.subscribeBanner}>Sign Up!</span>
              </h3>
              <div className={style.modalCopy}>
                Create a free account to vote on it
              </div>
            </div>
            <div className={style.accountOptions}>
              <OAuthSignInButton
                provider={'Facebook'}
                redirectUrl={createDippUrl(post.path)}
                isSignIn={false}
              />
              <OAuthSignInButton
                provider={'Google'}
                redirectUrl={createDippUrl(post.path)}
                isSignIn={false}
              />
              <Link
                className={style.linkButton}
                href={createRedirectPath('sign-up', post.path)}
              >
                <span>Sign Up With Email</span>
              </Link>
              <hr />
              <div className={style.modalCopy}>Already have an account?</div>
              <Link
                className={style.linkButton}
                href={createRedirectPath('sign-in', post.path)}
              >
                <span>Sign In</span>
              </Link>
            </div>
          </div>
        </DismissibleModal>
      ) : null}
      <div className={style.voteActions}>
        <span className={style.voteCount}>{upvoteCount - downvoteCount}</span>
        <div className={style.voteButtons}>
          <button
            className={style.voteButton}
            aria-label="upvote"
            onClick={(e) =>
              voteClickHandler(usersVote === LIKE ? NEUTRAL : LIKE, e)
            }
          >
            <Upvote
              className={style.pointer}
              stroke={usersVote === LIKE ? '#e2856e' : '#c4c4c4'}
              strokeWidth={2}
            />
          </button>
          <button
            className={style.voteButton}
            aria-label="downvote"
            onClick={(e) =>
              voteClickHandler(usersVote === DISLIKE ? NEUTRAL : DISLIKE, e)
            }
          >
            <Downvote
              className={style.pointer}
              stroke={usersVote === DISLIKE ? '#e2856e' : '#c4c4c4'}
              strokeWidth={2}
            />
          </button>
        </div>
      </div>
    </>
  )
}
