import { timeAgo } from 'lib/timeAgo'
import { useEffect, useState } from 'reactn'
import style from './index.module.css'

type Props = {
  createdAt: any
}
export const ClipTimeAgo = ({ createdAt }: Props) => {
  const [, setTick] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => setTick((tick) => tick + 1), 30000)
    return () => clearInterval(interval)
  }, [])

  return <span className={style.timeStamp}>{timeAgo(createdAt)}</span>
}
