import React from 'react'

export function Upvote({ stroke, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 15.745l-7.564-7.564a.616.616 0 00-.872 0L4 15.745"
        stroke={stroke || '#e2856e'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
