import React from 'react'
import style from './index.module.css'
import { ClipTimeAgo } from './ClipTimeAgo'
import Link from 'components/Link'

type Props = {
  createdAt: any
  title: string
  topic: string
}

export const ClipHeader = ({ createdAt, title, topic }: Props) => {
  return (
    <div className={style.container}>
      <div className={style.toplineContainer}>
        <div className={style.toplineInfo}>
          <Link className={style.topicLink} href={`${topic}`}>
            <span className={style.topic}>~ {topic} ~</span>
          </Link>
          <ClipTimeAgo createdAt={createdAt} />
        </div>
      </div>
      <div className={style.titleContainer}>{title}</div>
    </div>
  )
}
