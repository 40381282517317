/* eslint-disable jsx-a11y/click-events-have-key-events */
import cn from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import style from './index.module.css'
import { ProsedocRenderer } from 'components/ProsedocRenderer'
import Link from 'components/Link'

type Props = {
  prosedoc: any
  path: string
}
export const ClipBody = ({ prosedoc, path }: Props) => {
  const heightRef = useRef<HTMLDivElement | null>(null)

  const [bodyHeight, setBodyHeight] = useState(0)

  useEffect(() => {
    if (heightRef.current) {
      setBodyHeight(heightRef.current.clientHeight)
    }
  }, [])

  return (
    <div ref={heightRef}>
      <Link
        rel="ugc"
        className={cn(style.pointer, style.commentBody)}
        aria-label="Clip Body"
        href={`${path}`}
      >
        <div className={style.commentBodyContainer}>
          <ProsedocRenderer prosedoc={prosedoc} />

          <div className={style.commentBodyFader}></div>
        </div>
        <div className={style.bodyBottom}>
          <span className={style.revealText}>
            {bodyHeight > 300 ? 'View Entire Post' : 'Open Post'}
          </span>
        </div>
      </Link>
    </div>
  )
}
