import { useState } from 'react'
import { ViewportWrapper } from '../ViewportWrapper'
import { ImageSize, buildImgixURL } from '../../lib/images'
import { isBrowser } from 'lib/browser'

function buildSrcset(src: string, size: ImageSize) {
  const [width] = size
  return `${buildImgixURL(src, {
    size,
    dpr: 2,
  })} ${width * 2}w, ${buildImgixURL(src, {
    size,
  })} ${width}w`
}

interface ImagePayload {
  alt?: string
  size: ImageSize
  src: string
  className?: string
  sizeTabletUp?: ImageSize
  sizeDesktopUp?: ImageSize
  sizeWidescreenUp?: ImageSize
}

interface LazyImagePayload extends ImagePayload {
  alwaysDisplay?: boolean
}

function Image(props: ImagePayload) {
  const {
    src,
    size,
    sizeTabletUp,
    sizeDesktopUp,
    sizeWidescreenUp,
    className,
    alt,
  } = props

  // replace ghost url
  if (!src.includes('cms.thedipp.com')) {
    return <img className={className} src={src} alt={alt} />
  }

  return (
    <picture className={className}>
      {sizeWidescreenUp && (
        <source
          srcSet={buildSrcset(src, sizeWidescreenUp)}
          media="(min-width:1440px)"
        />
      )}
      {sizeDesktopUp && (
        <source
          srcSet={buildSrcset(src, sizeDesktopUp)}
          media="(min-width:1024px)"
        />
      )}
      {sizeTabletUp && (
        <source
          srcSet={buildSrcset(src, sizeTabletUp)}
          media="(min-width:768px)"
        />
      )}
      <img
        src={buildImgixURL(src, { size })}
        srcSet={buildSrcset(src, size)}
        alt={alt}
        {...{ width: size[0], height: size[1] }}
      />
    </picture>
  )
}

export default function LazyImage({
  alwaysDisplay = false,
  ...imageProps
}: LazyImagePayload) {
  const [inView, setInView] = useState(false)

  const height = 1
  const width = 1
  const offset = 500

  //render image html on SSR
  const imagePlaceHolder =
    isBrowser() || !alwaysDisplay ? (
      <div style={{ height, width }} />
    ) : (
      <Image {...imageProps} />
    )

  return (
    <ViewportWrapper onEnter={() => setInView(true)} offset={offset}>
      {inView ? <Image {...imageProps} /> : imagePlaceHolder}
    </ViewportWrapper>
  )
}
