import { AuthorAvatar } from 'components/AuthorAvatar'
import Link from 'components/Link'
import { CommunityTextPostClipFragment } from 'types/generated/CommunityTextPostClipFragment'
import style from './index.module.css'

interface Props {
  post: CommunityTextPostClipFragment
}
export const ClipAuthor = ({ post }: Props) => {
  const { author } = post
  return (
    <div>
      <div className={style.authorAside}>
        <div className={style.authorInfo}>
          <Link className={style.avatar} href={author.path as string}>
            {author.avatarProps && (
              <AuthorAvatar
                props={author.avatarProps}
                username={author.username || 'Unknown'}
              ></AuthorAvatar>
            )}
          </Link>
          <Link className={style.authorLink} href={author.path as string}>
            <span className={style.authorUsername}>
              {author.username || 'Unknown'}
            </span>
          </Link>
        </div>
      </div>
    </div>
  )
}
