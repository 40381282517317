import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import { CommunityTextPostClipFragment } from 'graphql/fragments/CommunityPostClip'
import {
  VoteOnCommunityPost,
  VoteOnCommunityPostVariables,
} from 'types/generated/VoteOnCommunityPost'
import { CommunityPostByPublicId_commPost } from 'types/generated/CommunityPostByPublicId'
import { useCommPostPermissions } from './useCommPostPermissions'
// import * as gtag from 'lib/gtag'

const VOTE_ON_COMMUNITY_POST_MUTATION = gql`
  mutation VoteOnCommunityPost($input: VoteOnCommPostInput!) {
    voteOnCommunityPost(input: $input) {
      communityPost {
        ...CommunityTextPostClipFragment
      }
    }
  }
  ${CommunityTextPostClipFragment}
`

const LIKE = 1
const NEUTRAL = 0
const DISLIKE = -1
type VoteType = typeof LIKE | typeof NEUTRAL | typeof DISLIKE

export const useVoteOnCommunityPost = (
  post: CommunityPostByPublicId_commPost
) => {
  const { canVote } = useCommPostPermissions()
  const [voteOnCommunityPost, { error }] = useMutation<
    VoteOnCommunityPost,
    VoteOnCommunityPostVariables
  >(VOTE_ON_COMMUNITY_POST_MUTATION)

  const commPostId = post.id

  const handleVote = (vote: VoteType) => {
    if (!canVote) {
      return null
    }
    voteOnCommunityPost({
      variables: { input: { commPostId, vote } },
    })

    // const voteType = vote ? (vote === 1 ? 'upvote' : 'downvote') : 'unvote'
    // gtag.community({
    //   voteType,
    // })
  }

  return {
    error,
    upvote: () => handleVote(1),
    unvote: () => handleVote(0),
    downvote: () => handleVote(-1),
  }
}
